@font-face {
  font-family: BrandonGrotesque-Bold;
  src: url("../assets/fonts/BrandonGrotesque-Bold.otf");
}

@font-face {
  font-family: BrandonGrotesque-Light;
  src: url("../assets/fonts/BrandonGrotesque-Light.otf");
}

@font-face {
  font-family: BrandonGrotesque-Regular;
  src: url("../assets/fonts/BrandonGrotesque-Regular.otf");
}

@font-face {
  font-family: Mic32Bold;
  src: url("../assets/fonts/Mic32Bold.ttf");
}

@font-face {
  font-family: Mic32Light;
  src: url("../assets/fonts/Mic32Light.ttf");
}

@font-face {
  font-family: Mic32Regular;
  src: url("../assets/fonts/Mic32Regular.ttf");
}
@font-face {
  font-family: Arimo-Regular;
  src: url("../assets/fonts/Arimo-Regular.ttf");
}
@font-face {
  font-family: Poppins-Regular;
  src: url("../assets/fonts/Poppins-Regular.otf");
}
@font-face {
  font-family: Assistant-Regular;
  src: url("../assets/fonts/Assistant-Regular.ttf");
}

html {
  height: 100%;
}

html > * {
  font-family: "Assistant-Regular";
}

body {
  overflow: auto;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

:root {
  --primary-blue: #002966;
  --primary-light-blue: #5bc4c7;
  /* change  old color:#ec4b26*/
  --primary-pink: #dc569e;
  --primary-orange: #ffc251;
  --primary-light-gray: #e6ebee;
  --secondery-orange: #fab24d;
  --background-dark: #171717;
  --background-light: #f6f6f6;
  --neutral-50p: #000000;
  --neutral-40p: #8f8f8f;
  --neutral-30p: #e0e0e0;
  --neutral-20p: #f2f2f4;
  --neutral-10p: #fefefe;
  --neutral-50p-dark: #efe2da;
  --red: #d32f2f;
  --red-shade: #ef5350;
  --orange: #ed6c02;
  --orange-shade: #ff9800;
  --blue: #002966;
  --blue-shade: #5bc4c7;
  --green: #2e7d32;
  --green-shade: #4caf50;
  --pink-company: #ff81ec;
  --nav-background: #ffffff;
  --nav-menu-background: #f5f5f5;
  --nav-text: #1e1e1e;
  --login-google: #f5f5f5;
  --login-facebook: #395185;
  --login-apple: #000000;
  --login-google-text: #848484;
  --login-faceboook-text: #ffffff;
  --login-apple-text: #ffffff;
}
